

    import $request from "@/modules/request/"
    
    export default {

        modules: [],
        
        groups: {
            list: [],
            perms: []
        },
        
        getSettings() {
            $request.get("app/settings").then(rsp => {
                this.modules = rsp.modules;
                this.groups.list = rsp.groups.list;
                this.groups.perms = rsp.groups.perms;
            });
        }

    }